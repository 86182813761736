@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

@import "react-modal.css";
/* Estilos globales */
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f6faff;
  color: #000;
  display: flex; /* Añade esto para que el contenido ocupe toda la altura de la pantalla */
  justify-content: center; /* Alineación horizontal al centro */
  align-items: center; /* Alineación vertical al centro */
  min-height: 100vh;
  padding: 10px;
}

.center-content {
  display: flex;
  align-items: center;
}

/* Componentes específicos */

.home-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.notfound-column,
.image-column,
.auth-column {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.image-column {
  padding-right: 20px;
  text-align: center;
  max-width: 480px;
}

img {
  max-width: 100%;
  height: auto;
}

.img-notfound {
  max-width: 60%;
  height: auto;
}

.not-found-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.not-found button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.not-found button:hover {
  background-color: #000080;
}

.slogan {
  max-width: 30%;
  text-align: center;
}

.header {
  padding: 0px 0;
  background-color: #f6faff;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.logo {
  width: 200px;
}

.green-ribbon {
  background-color: #69b53e;
  color: white;
  text-align: center;
  padding: 4px;
}

.blue-ribbon {
  background-color: #000080;
  color: white;
  text-align: center;
  padding: 4px;
}

.ribbon {
  padding: 15px 0;
}

.footer {
  padding: 0px 0;
  background-color: #f6faff;
  text-align: center;
  font-size: 20;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 3em;
}

.footer-links a {
  color: #000;
  text-decoration: none;
}

.footer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.footer-list li {
  margin-left: 10px;
  margin-right: 10px;
}

.waiting-list {
  text-align: center;
  padding: 10px;
}

.waiting-list h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.waiting-list p {
  font-size: 16px;
  margin-bottom: 10px;
}

.waiting-list-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.waiting-list form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waiting-list label {
  font-size: 16px;
  margin-bottom: 5px;
}

.waiting-list input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.waiting-list button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.waiting-list button:hover {
  background-color: #000080;
}

.login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.login h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.login form {
  display: flex;
  flex-direction: column;
}

.login label {
  display: block;
  margin-bottom: 5px;
  font-weight: inherit;
}

.login input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login input[type="text"],
.login input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login button:hover {
  background-color: #000080;
}

.register-list {
  flex: 1;
}

.register-list h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.register-list p {
  font-size: 16px;
  margin-bottom: 20px;
}

.register-list-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.register-list form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-list label {
  font-size: 16px;
  margin-bottom: 5px;
}

.register-list input {
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.register-list button {
  background-color: #69b53e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.register-list button:hover {
  background-color: #000080;
}

.privacy-policy,
.terms-of-use,
.cookie-policy,
.register,
.survey-form,
.login {
  border-radius: 10px;
  margin-top: 20px;
}

.container {
  max-width: 960px;
  margin: 0;
  padding: 0;
  padding-top: 50px;
  padding-bottom: 150px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.container-title {
  text-align: center;
}

/* Estilos generales del formulario de contacto */
.contact {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.contact label {
  display: block;
  margin-bottom: 5px;
  font-weight: inherit;
}

.contact input[type="text"],
.contact input[type="email"],
.contact textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.contact textarea {
  resize: vertical;
}

.contact button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact button[type="submit"]:hover {
  background-color: #000080;
}

/* Estilos específicos del reCAPTCHA */
.hcaptcha-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

/* Estilos del modal */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.modal-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.modal-body {
  margin-bottom: 15px;
}

.modal-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-button:hover {
  background-color: #000080;
}

/* Estilos del toggle de cookies */
.toggle-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.toggle-label {
  margin-right: 10px;
}

/* Estilos de la leyenda de cookies */
.cookie-legend {
  font-size: 12px;
  margin-top: 10px;
}

/* about.css */

.about {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.about p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown.show .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li a {
  color: #333;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  background-color: #f8f8f8;
}

.survey-success,
.registration-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.success-icon {
  font-size: 48px;
  color: #4caf50;
  margin-bottom: 20px;
}

.success-message {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.back-to-home {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-to-home:hover {
  background-color: #000080;
}

.social-icon {
  color: #69b53e;
}

.social-icon:hover {
  color: #000080;
}

.pre-register button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pre-register button:hover {
  background-color: #000080;
}

.survey-form button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #69b53e;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.survey-form button[type="submit"]:hover {
  background-color: #000080;
}

@media screen and (max-width: 600px) {
  body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    background-color: #f6faff;
    color: #000;
    box-sizing: initial;
  }

  .logo {
    width: 150px;
  }

  .home-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img {
    max-width: 50%;
  }

  .image-column {
    padding-right: 0;
  }

  .ribbon {
    padding: 5px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .footer-list li {
    margin: 8px;
  }

  .center-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .container {
    margin: 0%;
    padding-top: 50px;
    padding-bottom: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .footer {
    position: sticky;
  }

  .footer-ribbon {
    padding: 0px 0;
    background-color: #f6faff;
    text-align: center;
    font-size: 20;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
