/* styles.css */

/* Estilos para la pestaña de cookies */
.cookie-tab {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s ease;
}

.cookie-tab:hover {
  background-color: rgba(0, 0, 0, 1);
}

/* Estilos para el formulario de consentimiento */
.cookie-consent {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  margin-top: 20px;
}

.cookie-consent label {
  display: block;
  margin-bottom: 10px;
}

/* Estilos para el botón de configurar cookies */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Estilos para el fondo detrás del modal */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Estilos para el contenido del modal */
.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: 40%; /* Ajusta el ancho del modal según tu preferencia */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

/* Estilos para los toggles en el modal */
.ReactModal__Content label {
  display: block;
  margin-bottom: 10px;
}

/* Estilos para el contenido del modal */
.ReactModal__Confirm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Ajusta el ancho del modal según tu preferencia */
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%; /* Ajusta el ancho máximo del modal */
  width: 100%; /* Asegura que el modal use todo el ancho disponible */
  margin: auto; /* Centra el modal horizontalmente */
  box-sizing: border-box; /* Incluye padding en el ancho total */
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.input-container {
  display: flex;
  align-items: center; /* Alinea el texto y el campo de entrada verticalmente */
  gap: 10px; /* Espacio entre el campo de entrada y el texto */
  margin: 10px 0; /* Espacio arriba y abajo del contenedor */
}

.input-field {
  flex: 1; /* El campo de entrada ocupa el espacio disponible */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Incluye padding en el ancho total */
}

.input-label {
  font-size: 14px; /* Tamaño del texto */
  color: #333; /* Color del texto */
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Espacio entre los botones */
}

.input-field {
  width: 100%; /* Campo de entrada ocupa todo el ancho disponible */
  padding: 10px;
  margin: 10px 0; /* Espacio arriba y abajo del campo de entrada */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Incluye padding en el ancho total */
}

.button-container {
  display: flex;
  flex-direction: column; /* Alinea los botones verticalmente */
  gap: 15px; /* Espacio entre los botones */
}

@media (min-width: 768px) {
  /* Estilos para el contenido del modal */
  .ReactModal__Confirm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60%;
    width: 30%; /* Ajusta el ancho del modal según tu preferencia */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  .modal-buttons {
    flex-direction: row; /* Alinea los botones horizontalmente en pantallas grandes */
  }

  .cancel-button,
  .confirm-button {
    width: auto; /* Ancho automático en pantallas grandes */
    flex: 1; /* Botones ocupan espacio disponible en pantallas grandes */
  }
}
